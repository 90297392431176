import { KeyedCollection } from "../util/KeyedCollection";
import { ApplicationUser } from "../context/ApplicationUserContext";
import { isPlatform } from "@ionic/react";
import { AppType } from "../appTypes/AppType";
import defaultAppType from '../appTypes/default.json';
import mgrAppType from '../appTypes/mgr.json';
import employeeAppType from '../appTypes/employee.json';
import timeclockAppType from '../appTypes/timeclock.json';
import punchclockAppType from '../appTypes/punchclock.json';
import pwaAppType from '../appTypes/pwa.json';

export interface Constants {
  checkUnreadMessageCountTimer: number;
  refreshScheduleTimer: number;
  refreshTimeclockSettingsTimer: number;
  maxStaleAuthItemsInMinutes: number;
  minimumFeetForGeolocationUpdate: number;
  messagesReturnedPerPage: number;
  millisecondsToRefreshMessageCountOnMessagePage: number;
}

export interface EnvironmentType {
  constants: Constants;
  key: string;
  name: string;
  url: string;
}

const defaults: EnvironmentType = {
  constants: {
    checkUnreadMessageCountTimer: 30 * 1000, // how often to get just the unread messages
    refreshScheduleTimer: 60 * 1000, // how often to refresh events on Schedule/Clock Page
    refreshTimeclockSettingsTimer: 15 * 1000, // how often to get timclock settings on clock panel
    maxStaleAuthItemsInMinutes: 15, // minutes before we force auth items to refresh from api
    minimumFeetForGeolocationUpdate: 15.0, // how many feet before we consider it a position update
    messagesReturnedPerPage: 30, // how many messages for each scroll of the page, SYNC WITH MessagesV2Controller!!!
    millisecondsToRefreshMessageCountOnMessagePage: 6000, // millisecond-based interval to re-check for messages on Message Page
  },

  key: "prod",
  name: "",
  url: "",
};

const envTypes: EnvironmentType[] = [
  {
    ...defaults,
    key: "prod",
    name: "Production",
    url: "https://api.orbitalshift.com/",
  },
  {
    ...defaults,
    key: "sbx",
    name: "Sandbox",
    url: "https://sandboxapi.orbitalshift.com/",
  },
  {
    ...defaults,
    key: "loc",
    name: "Local",
    url: "http://localhost:5059/"
  },
  {
    ...defaults,
    key: "emu",
    name: "Local (Emu)",
    url: "http://10.0.2.2:54825/",
  },
  {
    ...defaults,
    key: "tst",
    name: "TestServer",
    url: "http://TestServer:8000/",
  },
  {
    ...defaults,
    key: "cor",
    name: "CoreAPI",
    url: "https://coreapi.orbitalshift.com/"
  }
];

const environments = new KeyedCollection<EnvironmentType>();
envTypes.map((et) => environments.add(et.key, et));


// ------------------------------------------------------------------------------------------------
// NOTE: versionName, versionCode, and revisionHash should be set to _REPLACE_XXXXX_XXX_ strings
//       that get replaced when 'build' is run from the command line.
//       See OSROOT/_buildenv/BuildModule/CommandLineFunctions.psm1
//       Needs more updates for Azure.
const settings = {
  env: environments,
  versionName: "2.2.1",
  versionCode: "76",
  revisionHash: "f8ee0a5",
  buildType: "pwa",
  appType: process.env.REACT_APP_APPTYPE ?? "pwa",
  appTypeSettings: getappTypeSettings("pwa"),
  userInterface: "MobilePwa",
  buildDate: "Thu 01/30/2025 1:12P",
  appVersionString: `2.2.1.76 (f8ee0a5)`,
  hasCapacitor: isPlatform("capacitor"),
  platform: isPlatform("ios")
    ? "ios"
    : isPlatform("android")
    ? "android"
    : "other",
};


// ------------------------------------------------------------------------------------------------
function getappTypeSettings(appType: string): AppType {
  let app = process.env.REACT_APP_APPTYPE;
  if (!app) {
    app = appType;
  }

  switch(app) {
    case "employee":
      return employeeAppType;
    case "mgr":
      return mgrAppType;
    case "timeclock":
      return timeclockAppType;
    case "punchclock":
      return punchclockAppType;
    case "pwa":
      return pwaAppType;
    default:
      return defaultAppType;
  }
}

// ------------------------------------------------------------------------------------------------
const getApiUrl = (user: ApplicationUser, endpoint: string): string => {
  return settings.env.item(user.environment).url + endpoint;
};

// ------------------------------------------------------------------------------------------------
const getCurrentEnvironment = (user: ApplicationUser): EnvironmentType => {
  return settings.env.item(user.environment);
};

// ------------------------------------------------------------------------------------------------
const getUserAndEnvironmentFromInput = (
  input: string
): { username: string; env: EnvironmentType } => {
  if (input.length < 4) {
    return { username: input, env: settings.env.item("prod") };
  }

  const envKey = input.toLowerCase().substring(0, 4);
  const username = input.substring(4);
  switch (envKey) {
    case "sbx ":
      return { username, env: settings.env.item("sbx") };
    case "loc ":
      return { username, env: settings.env.item("loc") };
    case "emu ":
      return { username, env: settings.env.item("emu") };
    case "tst ":
      return { username, env: settings.env.item("tst") };
    case "cor ":
      return { username, env: settings.env.item("cor") };
    default:
      return { username: input, env: settings.env.item("prod") };
  }
};

// ------------------------------------------------------------------------------------------------
const getEnvironmentFromInput = (input: string): EnvironmentType => {
  const envKey = input.toLowerCase().substring(0, 4);
  return settings.env.item(envKey);
}

// ------------------------------------------------------------------------------------------------
export const getConstants = (user: ApplicationUser): Constants => {
  if (!user.isLoggedin) {
    return defaults.constants;
  }

  const selection = settings.env.item(user.environment);
  if (selection === undefined || selection === null) {
    return defaults.constants;
  }

  return selection.constants;
};

const environment = {
  settings,
  getApiUrl,
  getCurrentEnvironment,
  getUserAndEnvironmentFromInput,
  getEnvironmentFromInput,
};

export default environment;

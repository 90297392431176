import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import env from "../appFunctions/environment";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";

// -----------------------------------------------------------------------------
async function submitFeedback(user: ApplicationUser, msg: string) {
    log("api_feedback_submitFeedback");

    const data = {
        version: env.settings.appVersionString,
        buildDate: env.settings.buildDate,
        environment: env.settings.env.item(user.environment).name,
        os: env.settings.platform,
        msg
    }

    return (await apiHelper.post(
        user,
        "api/v2/employees/submit-feedback",
        data)) as ApiErrorResult;
}

export const feedbackApi = {
    submitFeedback,
};
